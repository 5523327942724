<template>
  <div>
    <b-container fluid>
      <div class="pagdiv">
        <span class="pageTitle">告警事件</span>
      </div>
      <b-row class="borderColor">
        <div style="width: 100%">
          <div
            style="
              display: flex;
              align-items: center;
              margin: 10px;
              flex-wrap: wrap;
            "
          >
            <!-- <span v-if="selectValue.name === '開始時間'">
                    <date-picker style="width:auto; margin:5px" placeholder="開始時間" v-model="filterStartTime" valueType="format" format="HH:00:00" type="time"></date-picker>
                    ~
                    <date-picker style="width:auto; margin:5px" placeholder="結束時間" v-model="filterEndTime" valueType="format" format="HH:00:00" type="time"></date-picker>
                    </span> -->
            <div class="inputDiv" v-if="selectValue.name !== '開始時間'">
              <i class="fas fa-search mr-2"></i
              ><input
                type="text"
                name="filter"
                id=""
                v-model="searchInput"
                class="mr-2 filterInput"
                size="sm"
                :placeholder="'依' + selectValue.name + '搜尋'"
              />
              <span class="focus-border"></span>
            </div>
            <!-- <input v-if="selectValue.name !== '開始時間'" :placeholder="'依' + selectValue.name + '搜尋'"  style="margin:5px 5px" v-model="searchInput"> -->
            <select style="margin-right: 10px" v-model="selectValue">
              <option v-for="(i, index) in filterTitle" :key="index" :value="i">
                {{ i.name }}
              </option>
            </select>
            <div style="margin-left: 10px">
              <span style="font-weight: 700; font-size: 16px; margin-right: 5px"
                >依區域篩選:
              </span>
              <select style="margin-right: 10px" v-model="filterSite">
                <option
                  v-for="(i, index) in filterSiteArray"
                  :key="index"
                  :value="i.value"
                >
                  {{ i.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            style="
              margin: 2px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <p
              style="
                margin: 0px;
                padding: 0px;
                font-weight: 700;
                font-size: 18px;
              "
            >
              告警數量: {{ historyFilterSearch.length }}
            </p>
            <button
              class="confirmButton"
              style="margin: 0px 5px 8px 0px; width: 80px; padding: 2px"
              @click="getRecentAlarms"
            >
              更新
            </button>
          </div>
          <b-overlay :show="loadingData" :style="minHeight">
            <div class="othertableStyle tableWrap">
              <table>
                <tr class="sticky-top">
                  <th
                    v-for="(i, index) in thTitle"
                    :key="index"
                    class="sort"
                    :class="{ down: !i.isSort, up: i.isSort }"
                    @click="sortHistoryData(i.sortName, index)"
                  >
                    <span v-if="i.name === '案場'"
                      ><i
                        class="fas fa-globe-americas"
                        style="margin-right: 5px; color: blue"
                      ></i
                      >{{ i.name }}</span
                    >
                    <span v-else>{{ i.name }}</span>
                  </th>
                  <th>內容</th>
                </tr>
                <tr v-for="(i, index) in historyFilterSearch" :key="index">
                  <td @click="openMap(i.factoryName)" style="cursor: pointer">
                    {{ i.factoryName }}
                  </td>
                  <td>{{ i.county }}</td>
                  <td>{{ i.maintainer }}</td>
                  <td>{{ i.which }}</td>
                  <td>{{ i.lastAlarmType }}</td>
                  <td>{{ i.time }}</td>
                  <td :style="levelColor(i.lastColorLabel)">
                    {{ i.allAlarms.length }}
                  </td>
                  <td
                    @click.stop="getModalData(i.allAlarms, i.factoryName)"
                    style="cursor: pointer"
                  >
                    ...
                  </td>
                </tr>
              </table>
            </div>
          </b-overlay>
        </div>
      </b-row>
      <b-modal id="settingModal" size="xl" :title="modalTitle" ok-only>
        <div class="othertableStyle">
          <table>
            <tr>
              <th>設備</th>
              <th>屬性</th>
              <th>詳細內容</th>
              <!-- <th>開始時間</th> -->
            </tr>
            <tr v-for="(i, idx) in modalData" :key="idx">
              <td>{{ invOrNot(i.inv) }}</td>
              <td>{{ i.alarmType }}</td>
              <td style="text-align: left; word-break: break-all">
                {{ i.desc }}
              </td>
              <!-- <td>{{changeTime(i.timestamp)}}</td> -->
            </tr>
          </table>
        </div>
      </b-modal>
      <b-modal id="mapMpdal" size="xl" title="告警地圖" ok-only>
        <div>
          <GmapMap
            ref="mapRef"
            :center="center"
            style="width: 100%; height: 600px"
            :zoom="12"
            :options="{
              scrollwheel: true,
            }"
          >
            <InfoWindow
              v-for="(x, index) in markers"
              :key="index"
              :position="x.position"
              :opened="true"
              :draggable="false"
              :options="{
                content: `<div style='${x.color}; width: auto; color:rgb(40, 40, 40); font-weight:700; font-size: 14px'>${x.factoryName}</div>`,
                pixelOffset: {
                  width: 0,
                  height: 0,
                },
              }"
            ></InfoWindow>
          </GmapMap>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BModal, BOverlay } from 'bootstrap-vue'
import { getHistoryAlarmsApi } from '../api'
import { DateTime } from 'luxon'
import { InfoWindow } from 'vue2-google-maps'
export default {
  components: {
    BModal,
    BOverlay,
    InfoWindow
  },
  data () {
    return {
      center: { lat: 23.789212, lng: 121.468511 },
      markers: [],
      filterSite: [],
      filterStartTime: '',
      filterEndTime: '',
      recentAlarmsData: [],
      rebuildAlarmsData: [],
      modalData: [],
      modalTitle: '',
      thTitle: [
        {
          name: '案場',
          sortName: 'factoryName',
          isSort: true
        },
        {
          name: '縣市',
          sortName: 'county',
          isSort: true
        },
        {
          name: '工程師',
          sortName: 'maintainer',
          isSort: true
        },
        {
          name: '設備',
          sortName: 'which',
          isSort: true
        },
        {
          name: '屬性',
          sortName: 'lastAlarmType',
          isSort: true
        },
        {
          name: '開始時間',
          sortName: 'time',
          isSort: true
        },
        {
          name: '影響',
          sortName: 'allAlarms',
          isSort: true
        }
      ],
      searchInput: '',
      selectValue: {
        name: '案場',
        sortName: 'factoryName',
        isSort: true
      },
      loadingData: false
    }
  },
  computed: {
    ...mapState(['filterSiteArray', 'factoryInfo']),
    filterTitle () {
      return this.thTitle.filter(
        (x) => x.name !== '影響' && x.name !== '開始時間'
      )
    },
    historyFilterSearch () {
      // if (
      //   this.selectValue.name === '開始時間' &&
      //   this.filterStartTime !== '' &&
      //   this.filterEndTime !== ''
      // ) {
      //   if (this.filterSite.length === 0) {
      //     return this.rebuildAlarmsData.filter(
      //       (x) =>
      //         x['time'].slice(-8) >= this.filterStartTime &&
      //         x['time'].slice(-8) <= this.filterEndTime
      //     )
      //   } else {
      //     return this.rebuildAlarmsData.filter(
      //       (x) =>
      //         x['time'].slice(-8) >= this.filterStartTime &&
      //         x['time'].slice(-8) <= this.filterEndTime &&
      //         this.filterSite.includes(x['county'])
      //     )
      //   }
      // }
      if (this.filterSite.length > 0) {
        return this.rebuildAlarmsData.filter(
          (resources) =>
            resources[`${this.selectValue.sortName}`].match(this.searchInput) &&
            this.filterSite.includes(resources['county'])
        )
      }
      return this.rebuildAlarmsData.filter((resources) =>
        resources[`${this.selectValue.sortName}`].match(this.searchInput)
      )
    },
    minHeight () {
      const height = this.loadingData ? 'min-height: 75vh' : 'min-height: auto'
      return height
    }
  },
  methods: {
    ...mapActions(['asyncInfo']),
    async getRecentAlarms () {
      this.loadingData = true
      this.rebuildAlarmsData = []
      const recentAlarms = await getHistoryAlarmsApi()
      if (recentAlarms?.data?.success) {
        this.recentAlarmsData = recentAlarms.data.data
        this.dataBuild()
      }
      this.loadingData = false
    },
    dataBuild () {
      let array = []
      for (let i = 0; i < this.recentAlarmsData.length; i++) {
        let differentArray = []
        this.recentAlarmsData[i].allAlarms.forEach((x) => {
          differentArray.push({
            inv: x.inv,
            alarmType: x.alarmType
          })
        })
        differentArray = this.dedup(differentArray)
        for (let j = 0; j < differentArray.length; j++) {
          const arr = this.recentAlarmsData[i].allAlarms.filter(
            (x) =>
              x.inv === differentArray[j].inv &&
              x.alarmType === differentArray[j].alarmType
          )
          arr.map((x) => {
            if (x.level === 2) x.alarmType = '發電數據異常'
            // TODO: 無發電數據 -> 發電數據異常，之後要改回來

            // switch (x.level) {
            //   case 2:
            //     x.alarmType = '發電數據異常'
            //     break
            //   case 3:
            //     x.alarmType = '部分通訊異常/裝置斷訊'
            //     break
            //   case 6:
            //     x.alarmType = '系統未連線'
            //     break
            //   case 7:
            //     x.alarmType = '系統短暫斷線'
            //     break
            //   default:
            //     x.alarmType = '-'
            //     break
            // }
          })

          const inv = this.invOrNot(differentArray[j].inv)

          array.push({
            which: inv,
            lastAlarmType: arr[arr.length - 1].alarmType,
            lastColorLabel: arr[arr.length - 1].colorLabel,
            time: DateTime.fromISO(arr[arr.length - 1].timestamp).toFormat(
              'yyyy-MM-dd HH:mm:ss'
            ),
            factoryName: this.recentAlarmsData[i].factoryName,
            county: this.recentAlarmsData[i].county,
            maintainer: this.recentAlarmsData[i].maintainer,
            allAlarms: arr
          })
        }
      }
      this.rebuildAlarmsData = array
      this.sortHistoryData('lastAlarmType', 4)
    },
    // 重複的濾除
    dedup (arr) {
      let hashTable = {}
      return arr.filter(function (el) {
        let key = JSON.stringify(el)
        let match = Boolean(hashTable[key])
        let res = match ? false : (hashTable[key] = true)
        return res
      })
    },
    sortHistoryData (sortType, idx) {
      // if (sortType === 'lastAlarmType') {
      //   let sortData = this.rebuildAlarmsData.map((item) => {
      //     switch (item.lastAlarmType) {
      //       case '系統未連線':
      //         item.sortNo = 1
      //         break
      //       case '系統短暫斷線':
      //         item.sortNo = 2
      //         break
      //       case '發電數據異常':
      //         item.sortNo = 3
      //         break
      //       case '裝置斷訊':
      //         item.sortNo = 5
      //         break
      //       case '部分通訊異常':
      //         item.sortNo = 4
      //         break
      //       default:
      //         item.sortNo = 6
      //         break
      //     }
      //     return item
      //   })
      //   this.rebuildAlarmsData = sortData.sort((a, b) => a.sortNo - b.sortNo)
      // }
      this.rebuildAlarmsData = this.rebuildAlarmsData.sort((a, b) => {
        if (sortType === 'lastAlarmType') {
          const stringToOrder = (type) => {
            let order = 0
            switch (type) {
              case '系統未連線':
                order = 1
                break
              case '系統短暫斷線':
                order = 2
                break
              case '發電數據異常':
                order = 3
                break
              case '裝置斷訊':
                order = 4
                break
              case '部分通訊異常':
                order = 5
                break
              case '發電效率不佳':
                order = 6
                break
              default:
                order = 7
                break
            }
            return order
          }

          const aStr = stringToOrder(a[sortType])
          const bStr = stringToOrder(b[sortType])

          return aStr - bStr
        } else {
          return a[sortType] > b[sortType] ? 1 : -1
        }
      })
      if (!this.thTitle[idx].isSort) {
        this.rebuildAlarmsData = this.rebuildAlarmsData.reverse()
      }
      this.thTitle[idx].isSort = !this.thTitle[idx].isSort
    },
    levelColor (color) {
      const width = 'width: 50px'
      if (color === 'red') {
        return `background: rgb(255,139,142); ${width};`
      } else if (color === 'orange') {
        return `background: orange; ${width};`
      } else {
        return `background: white; ${width};`
      }
    },
    getModalData (arr, factory) {
      this.modalData = arr
      this.modalTitle = factory
      this.$bvModal.show('settingModal')
    },
    changeTime (time) {
      return DateTime.fromISO(time).toFormat('yyyy-MM-dd HH:mm:ss')
    },
    invOrNot (device) {
      let inv =
        typeof device === 'number' ? 'Inv' + ('0' + device).slice(-2) : device
      if (device === null) inv = '系統斷線'
      return inv
    },
    openMap (factory) {
      this.$bvModal.show('mapMpdal')
      this.center = {}
      setTimeout(() => this.setCenter(factory), 400)
    },
    setCenter (factory) {
      const whichFactory = this.markers.find((x) => x.factoryName === factory)
      this.center = whichFactory.position
    },
    buildAlarmFactoryLng () {
      this.markers = []
      const filterAlarmFactory = this.factoryInfo
        .filter((x) => {
          return this.recentAlarmsData
            .map((y) => y.factoryName)
            .includes(x.factory)
        })
        .map((x) => {
          const thisFactory = this.recentAlarmsData.find(
            (y) => y.factoryName === x.factory
          )
          const mapColor = this.buildMapColor(thisFactory.allAlarms)
          return {
            factoryName: x.factory,
            position: {
              lng: x.informations.longitude,
              lat: x.informations.latitude
            },
            color: mapColor
          }
        })
      this.markers = filterAlarmFactory
    },
    buildMapColor (arr) {
      const colorTypeArr = ['red', 'yellow', 'blue', 'orange']
      for (let i = 0; i < colorTypeArr.length; i++) {
        if (arr.find((x) => x.colorLabel === colorTypeArr[i])) {
          return this.levelColor(colorTypeArr[i])
        }
      }
    }
  },
  async created () {
    await Promise.all([this.getRecentAlarms(), this.asyncInfo()])
    await this.buildAlarmFactoryLng()
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/global.scss";
@import "../assets/scss/customVariables.scss";
</style>
<style scoped lang="scss">
.sort {
  position: relative;
  cursor: pointer;
}
.sort::after {
  content: "";
  display: block;
  position: absolute;
  right: 2px;
  top: calc(50% - 9px);
  width: 20px;
  height: 20px;
}
.down::after {
  background: #7dc6cd url(../image/sortBtn02.png);
  background-size: 100%;
}
.up::after {
  background: #7dc6cd url(../image/sortBtn03.png);
  background-size: 100%;
}
.inputwidth {
  width: 100px;
}
.normal {
  color: rgb(109, 139, 109);
}
table {
  width: 100% !important;
}

table th {
  text-align: center;
}
table td {
  font-size: 17px;
  font-weight: 700;
  padding: 7px;
  text-align: center;
  max-width: 340px;
  min-width: 70px;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
.iconstyle {
  font-size: 20px;
  margin: 3px;
  color: gray;
}

.tableWrap {
  height: calc(100vh - 220px);
  overflow-x: auto;

  table {
    th {
      background-color: rgb(245, 245, 245);
    }
  }
  @media screen and (max-width: 552px) {
    height: calc(100vh - 295px);
  }
}
</style>
